.header {
  background: #295c88;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
  padding: 8px 16px;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9;
}

.fsLogo {
  width: 165px;
  margin-bottom: 0.7em;
  cursor: pointer;
  padding-left: 15px;
  padding-top: 8px;
}

.navbar {
  background-color: #295c88 !important;
  color: white !important;
  padding: 0px !important;
  height: 55px;
  position: fixed !important;
  top: 0 !important;
  width: 100% !important;
  z-index: 9999;
}

#navbarTogglerDemo02 {
  background-color: #295c88;
  padding-left: 8px;
  padding-right: 8px;
  z-index: 9;
}

.header .right .avatar {
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
}

.header .logo {
  width: 150px;
  height: auto;
}

.header .right {
  display: flex;
  align-items: center;
}

.header .right .name {
  margin-right: 6px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
}

.header .right .name:hover {
  color: rgba(255, 255, 255, 0.75);
}

.profileImage {
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: inline-block;
  width: 30px;
  height: 30px;
  vertical-align: top;
  margin: 6px;
}

.userName {
  font-size: 16px;
  padding-top: 10px;
  display: block;
  line-height: 0px;
  color: rgba(255, 255, 255, 0.5);
  font-family: 'Open Sans', sans-serif;
}
.companyName {
  font-size: 12px;
  padding-top: 15px;
  display: block;
  line-height: 0px;
  color: rgba(255, 255, 255, 0.5);
  font-family: 'Open Sans', sans-serif;
}

.userDetailsContainer {
  justify-content: space-between;
}

.header-container {
  background-color: #295c88 !important;
  color: white !important;
}
.header-userDetails-container {
  background-color: #295c88 !important;
  color: white !important;
  padding-left: 35px;
}

.header-userDetails-container:hover {
  cursor: pointer;
}

.header-links {
  border-color: transparent !important;
  color: rgba(255, 255, 255, 0.5) !important;
  font-size: 16px !important;
  line-height: 35px !important;
  padding: 0.5rem 1rem;
  outline: 0 !important;
  cursor: pointer !important;
  height: 47px !important;
  font-weight: 300 !important;
  text-transform: none !important;
}

.nav-link:hover {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.75) !important;
  font-family: 'Open Sans', sans-serif;
}

@media screen and (max-width: 991px) {
  .header-userDetails-container {
    padding-left: 16px !important;
  }
}
