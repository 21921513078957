.transaction-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.transaction-wrapper {
  padding: 10px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
