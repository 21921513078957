.app-container {
  background-image: url('https://softwareskills.se/images/header.jpg');
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100vh;
}

.app-wrapper {
  background-color: rgba(192, 192, 192, 0.7);
  /* opacity: 1; */
  border-radius: 15px;
  padding: 15px;
  max-width: 80%;
}

.transaction-button {
  background-color: #295c88 !important;
}
